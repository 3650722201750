<template>
  <div>
    <div class="condition">
      <div class="condition_item">
        <el-input
          style="width: 246px; margin-right: 10px"
          v-model="customerName"
          placeholder="请输入客户名称"
        ></el-input>
        <el-button
          class="button_default"
          style="margin-left: 10px"
          @click="query(true)"
          >查询</el-button
        >
      </div>
    </div>
    <div class="table">
      <el-table
        stripe
        :header-cell-style="{ background: '#E8EAEF', color: '#3D3D3D' }"
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        :header-row-class-name="tableRowClassName"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          align="center"
          prop="customerId"
          label="客户ID"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="customerName"
          label="客户名称"
        ></el-table-column>
        <el-table-column label="位置偏移告警距离设置(单位：米)" align="center">
          <template slot-scope="scope">
            <div
              class="edit-block"
              v-if="
                !(
                  scope.row.isEdit &&
                  scope.row.editBlock == 'positionDeviateSet'
                )
              "
              @click="editRow(scope.row, 'positionDeviateSet')"
            >
              {{ scope.row.positionDeviateSet }}
            </div>
            <div v-else>
              <el-input
                type="number"
                placeholder="位置偏移告警距离设置"
                v-model="scope.row.positionDeviateSet"
                @blur="saveRow(scope.row, 'positionDeviateSet')"
              >
              </el-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="连续不通电告警时长(单位：小时)" align="center">
          <template slot-scope="scope">
            <div
              class="edit-block"
              v-if="
                !(scope.row.isEdit && scope.row.editBlock == 'overPowerDay')
              "
              @click="editRow(scope.row, 'overPowerDay')"
            >
              {{ scope.row.overPowerDay }}
            </div>
            <div v-else>
              <el-input
                type="number"
                placeholder="连续不通电告警时长"
                v-model="scope.row.overPowerDay"
                @blur="saveRow(scope.row, 'overPowerDay')"
              >
              </el-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="createTime" label="创建时间">
          <template slot-scope="scope">
            {{ scope.row.createTime | formattingDate }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="modifyTime" label="修改时间">
          <template slot-scope="scope">
            {{ scope.row.createTime | formattingDate }}
          </template>
        </el-table-column>
        <!-- <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button
              class="edit el-icon-edit"
              type="text"
              @click="edit(scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column> -->
      </el-table>
      <div class="app-page-container">
        <span @click="first">首页</span>
        <el-pagination
          layout="pager"
          :hide-on-single-page="false"
          :page-size="12"
          :current-page.sync="currentPage"
          :total="recordCount"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
        <span @click="last">尾页</span>
      </div>
    </div>
    <el-dialog
      title="编辑"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <el-form label-position="right" label-width="160px" :model="compileData">
        <el-form-item label="客户名称">
          <el-select
            style="width: 100%"
            v-model.number="compileData.customerId"
            placeholder="请选择"
            disabled
          >
            <el-option
              v-for="item in customer"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="位置偏移告警距离设置">
          <el-input-number
            v-model="compileData.positionDeviateSet"
            :min="1"
          ></el-input-number>
          米
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  clientName,
  saveCustomerConfig,
  selectCustomerList
} from "@/api/AssetManagement";

export default {
  name: "ConfigManage",
  data() {
    return {
      customerName: "",
      customerID: null,
      customer: [],
      compileData: {},
      pageSize: 12, //每页数据条数
      recordCount: 0,
      currentPage: 1, //当前页数
      tableData: [],
      dialogVisible: false
    };
  },
  created() {
    this.getClient();
    this.query(false);
  },
  methods: {
    editRow(row, key) {
      this.tableData.forEach(item => {
        if (item.customerId == row.customerId) {
          item.isEdit = true;
          item.editBlock = key;
        } else {
          item.isEdit = false;
        }
      });
    },
    //分页首页
    first() {
      this.tableData = [];
      this.currentPage = 1;
      this.pages = 1;
      this.query(true);
    },
    //分页最后一页
    last() {
      this.currentPage = this.recordCount;
      this.query(true);
    },
    handleCurrentChange(val) {
      this.pages = val;
      this.query(true);
    },
    //获取客户列表
    getClient() {
      clientName({}).then(res => {
        this.customer = res.data.data;
      });
    },
    //查询
    query(type) {
      if (!type) {
        this.pages = 1;
      }
      let data = {
        limit: 12,
        curPage: this.pages,
        customerName: this.customerName
      };
      selectCustomerList(data).then(res => {
        this.tableData = res.data.data.records.map(item => {
          item.isEdit = false;
          item.editBlock = "";
          return item;
        });
        this.recordCount = res.data.data.total;
      });
    },
    tableRowClassName(row, rowIndex) {
      if (rowIndex === 0) return "headerStyle";
    },
    //表格多选框
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //对话框
    handleClose(done) {
      done();
    },
    //编辑保存
    saveRow(row, key) {
      const value = row[key];
      if (value < 0) {
        this.$message.error("不允许输入负数");
        return;
      }
      var compileData = {
        customerId: row.customerId
      };
      compileData[key] = value;
      saveCustomerConfig(compileData)
        .then(({ data }) => {
          this.dialogVisible = false;
          if (data.resultCode === 0) {
            this.$message.success("修改成功");
          } else {
            this.$message.error("修改失败");
          }
          this.query();
        })
        .catch(res => {
          this.$message.error("修改失败" + res);
        });
    }
  }
};
</script>

<style scoped>
.condition {
  margin-bottom: 20px;
}

.edit-block {
  min-width: 100px;
  min-height: 30px;
}
</style>
